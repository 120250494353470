import RailsNestedForm from '@stimulus-components/rails-nested-form/dist/stimulus-rails-nested-form.umd.js'

export default class extends RailsNestedForm {
  static targets = ['button']
  static values = {
    limit: {
      type: Number,
      default: 1
    }
  }

  connect() {
    if (!this.hasButtonTarget) { return }

    if (this.nestedForms.length >= this.limitValue) {
      this.buttonTarget.classList.add('hidden')
    }
  }

  add(e) {
    if (this.nestedForms.length >= this.limitValue) {
      return;
    }

    super.add(e)

    if (!this.hasButtonTarget) { return }

    if (this.nestedForms.length >= this.limitValue) {
      this.buttonTarget.classList.add('hidden')
    }
  }

  remove(e) {
    super.remove(e)

    if (!this.hasButtonTarget) { return }

    if (this.nestedForms.length < this.limitValue) {
      this.buttonTarget.classList.remove('hidden')
    }
  }

  get nestedForms() {
    return this.element.querySelectorAll(this.wrapperSelectorValue)
  }
}
