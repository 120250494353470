import { Controller } from '@hotwired/stimulus'
import { flashEffect } from 'helpers/effects'
import { generateEid, randomId } from 'helpers'

export default class extends Controller {
  static values = {
    removableAttrs: {
      type: Array,
      default: ['data-admin--paired-relations-eids-value']
    },
    clearableTargets: {
      type: Array,
      default: ['data-admin-clone-relation-sentence-target="clearable"']
    }
  }

  clone() {
    const source = this.element.closest('.relation_sentences')

    const node = source.cloneNode(true)

    this._resetIds(node)
    this._removeAttrs(node)
    this._clearElements(node)
    this._updateEid(node)

    const wrapperNode = source.parentElement
    // append the new node after the source element
    wrapperNode.insertBefore(node, source.nextSibling)

    flashEffect(this, {
      message: 'Sentence cloned successfully' ,
      wrapperClass: 'alert-info',
      icons: ['fa-solid', 'fa-triangle-exclamation']
    })
  }

  _resetIds(destination) {
    destination.querySelectorAll('[id]').forEach((el) => {
      const newId = randomId()

      const label = destination.querySelector(`[for="${el.id}"]`)
      if (label) { label.for = newId }

      el.id = newId
    })
  }

  _removeAttrs(destination) {
    this.removableAttrsValue.forEach((attr) => {
      destination.querySelectorAll(`[${attr}]`).forEach((el) => {
        el.removeAttribute(attr)
      })
    })
  }

  _clearElements(destination) {
    this.clearableTargetsValue.forEach((attr) => {
      destination.querySelectorAll(`[${attr}]`).forEach((el) => {
        el.replaceChildren()
      })
    })
  }

  _updateEid(destination) {
    const eid = generateEid()
    destination.dataset.eid = eid
    destination.querySelector('[data-field-name="eid"]').value = eid
    destination.id = `eid_${eid}`
  }
}
