import { Controller } from '@hotwired/stimulus';
import { dedup } from 'helpers'

export default class extends Controller {
  static targets = ['output', 'template', 'element', 'input']
  static outlets = ['annotate']
  newVarValue;

  inputTargetConnected(element) {
    if (element.value) {
      element.dataset.previousValue = element.value
    }
  }

  push(event) {
    this.newVarValue = event.currentTarget.closest('.grouped-fields')?.querySelector('[data-field-name="variable"]')?.value
    if (!this.newVarValue) { return }

    const abbrValue = event.currentTarget.closest('.grouped-fields')?.querySelector('[data-field-name="abbr"]')?.value

    let matchingElements = []

    if (event.currentTarget.dataset.fieldName == 'abbr') {
      if (!abbrValue) { return }

      matchingElements = Array.from(document.querySelectorAll('input'))
        // skip matches on fields which are not variable or abbr
        // e.g. matches on abbr_expls
        .filter((el) => el.dataset.fieldName?.includes('variable') || el.dataset.fieldName?.includes('abbr'))
          // matches abbr in variable or abbr value
        .filter((el) => this._ciEquals(el.value, abbrValue))
    } else if (event.currentTarget.dataset.fieldName == 'variable') {
      const previousValue = event.currentTarget.dataset.previousValue

      matchingElements = Array.from(document.querySelectorAll('input'))
        // skip matches on fields which are not variable or abbr
        // e.g. matches on abbr_expls
        .filter((el) => el.dataset.fieldName?.includes('variable') || el.dataset.fieldName?.includes('abbr'))
        .filter((el) => {
          // matches variable in variable value
          if (previousValue && this._ciEquals(el.value, previousValue)) { return true }

          // matches abbr in variable or abbr value
          if (abbrValue && this._ciEquals(el.value, abbrValue)) { return true }

          return false
        })

      event.currentTarget.setAttribute('value', this.newVarValue)
      event.currentTarget.dataset.previousValue = this.newVarValue
    }

    // clear out the outputTarget
    this.outputTarget.replaceChildren()

    const matchingVariableElements = matchingElements.map((el) => {
      if (el === event.currentTarget) { return }

      let element = null

      if (el.dataset.fieldName?.includes('variable')) {
        element = el
      } else if (el.dataset.fieldName == 'independent_abbr') {
        element = el.closest('.grouped-fields')?.querySelector('[data-field-name="independent_variable"]')
      } else if (el.dataset.fieldName == 'dependent_abbr') {
        element = el.closest('.grouped-fields')?.querySelector('[data-field-name="dependent_variable"]')
      } else if (el.dataset.fieldName == 'moderator_abbr') {
        element = el.closest('.grouped-fields')?.querySelector('[data-field-name="moderator_variable"]')
      } else if (el.dataset.fieldName == 'mediator_abbr') {
        element = el.closest('.grouped-fields')?.querySelector('[data-field-name="mediator_variable"]')
      } else {
        // matched on both abbr and var
        // so we can ignore
        return
      }

      if (!element) { return }

      if (this._ciEquals(element.value, this.newVarValue)) { return }

      return element
    }).filter((el) => el)

    // lookup by abbr or variable causes duplicates,
    // so remove them before render
    dedup(matchingVariableElements).forEach((el) => this._renderInput(el))

    // don't show modal if no elements to update
    if (matchingVariableElements.length > 0) {
      this.dispatch('push', { detail: { boxId: 'propagate_changes' } })
    }
  }

  apply(event) {
    this.elementTargets.forEach((target) => {
      if (target.checked) {
        const element = document.getElementById(target.value)
        element.value = this.newVarValue
        this.annotateOutlets.forEach((outlet) => {
          if (outlet.element.contains(element)) {
            outlet.updateText({ target: element })
          }
        })
      }
    })
    this.dispatch('apply', { target: event.currentTarget })
  }

  _renderInput(element) {
    const elementLocation = this._findTabName(element)

    let content = this.templateTarget.innerHTML.replace(/TMPLID/g, element.id)
    content = content.replace(/TMPLLABEL/g,
                              `Update "${element.value}" to "${this.newVarValue}" on "${elementLocation}"`)

    this.outputTarget.insertAdjacentHTML('beforeend', content)
  }

  _findTabName(element) {
    const tabContent = element.closest('.tab-content')
    const tabLink = document.querySelector(`[data-tab="${tabContent.id}"]`)

    return tabLink.innerText
  }

  _ciEquals(a, b) {
    return a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
  }
}
