import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item']

  itemTargetConnected(target) {
    if (!target.value) {
      const itemCounters = Array.from(this.itemTargets).map((el) => Number(el.value))
      target.value = itemCounters.length === 0 ? 0 : Math.max(...itemCounters) + 1
      target.dispatchEvent(new InputEvent('input'))
    }
  }
}
