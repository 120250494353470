import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'
import { flashEffect } from '../../helpers/effects';
import { showModal } from '../../helpers/modals';
import { initializeLSArray, addLSElement,
  removeLSElementByName, clearLSArray, deleteLSArray,
  getLSElements} from '../../helpers/local_storage_array';


export default class extends Controller {
  static targets = ['checkbox', 'bar', 'toggleElement',
    'counter', 'table']
  static values = { url: String, entity: String }

  localStorageKey;

  initialize() {
    this.localStorageKey = `${this.entityValue}_batch_items`
    deleteLSArray(this.localStorageKey)
  }

  connect() {
    // this.localStorageKey = `${this.entityValue}_batch_items`
    initializeLSArray(this.localStorageKey)
  }

  disconnect() {
    deleteLSArray(this.localStorageKey)
  }

  submit(event) {
    if (event.detail.success) {
      console.log('Form successfully submitted')
      window.location.reload();
    } else {
      console.log('Error!')
    }
  }

  checkboxTargetConnected(target) {
    if (getLSElements(this.localStorageKey).includes(target.value)) {
      target.checked = true;
    }
  }

  toggleGroup(event) {
    if (event.currentTarget.checked === true) {
      this.checkboxTargets.forEach(item => {
        item.checked = true;
        addLSElement(this.localStorageKey, item.value)
      })
      this.showBar()
    } else {
      this.checkboxTargets.forEach(item => {
        item.checked = false;
        removeLSElementByName(this.localStorageKey, item.value)
      })
      if (getLSElements(this.localStorageKey).length === 0 ) { this.hideBar() }
    }
    this.setSelected()
  }

  deslectAll() {
    this.toggleElementTarget.checked = false
    this.checkboxTargets.forEach(item => {
      item.checked = false;
    })
    clearLSArray(this.localStorageKey)
  }

  async process(event) {
    let target  = event.currentTarget

    if (!target.dataset) { return }
    let ids = getLSElements(this.localStorageKey);
    if (ids.length === 0) {
      flashEffect(this, {
        message: 'You need to select records',
        wrapperClass: 'alert-error',
        icons: ['fa-solid', 'fa-triangle-exclamation']
      })
      return
    }
    if (target.dataset.format == 'new_record') {
      let idsString = ids.join(',')
      let dData =  new FormData();
      dData.set('ids', idsString);
      const response = await get(target.dataset.url, {
        query: dData,
        responseKind: 'turbo-stream'
      })
      if (response.ok && response.response.status != 210) {
        showModal(target.dataset.boxId)
      }
    }
  }

  setSelected() {
    this.counterTarget.innerHTML = this.selectedCount()
  }

  selectedCount() {
    return getLSElements(this.localStorageKey).length
  }

  singleSelect(event) {
    if (event.currentTarget.checked) {
      addLSElement(this.localStorageKey, event.currentTarget.value)
    } else {
      removeLSElementByName(this.localStorageKey, event.currentTarget.value)
    }

    let checkedCount = this.selectedCount()
    if (checkedCount > 0) {
      this.showBar()
    } else  {
      this.hideBar()
      this.toggleElementTarget.checked = false
    }
    this.setSelected()
  }

  showBar() {
    this.barTarget.classList.add('bar-show');
    this.barTarget.classList.add('zoom-in');
    this.barTarget.classList.remove('bar-hide');
    this.barTarget.classList.remove('zoom-out');
  }

  hideBar() {
    this.barTarget.classList.remove('zoom-in');
    this.barTarget.classList.add('zoom-out');
    setTimeout((() => {
      this.barTarget.classList.remove('bar-show');
      this.barTarget.classList.add('bar-hide');
    }), 200);
    this.deslectAll()
  }
}
