import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["input", "results", "resultItems"];
  static values = { items: Array }

  connect() {
    this.currentFocus = -1;
  }

  onInput(_event) {
    const val = this.inputTarget.value;
    this.closeAllLists();
    if (!val) return false;

    this.currentFocus = -1;
    this.resultsTarget.classList.add('open');

    this.itemsValue.forEach((item) => {
      let name = item.name
      if (name.substr(0, val.length).toUpperCase() === val.toUpperCase()) {
        const suggestionDiv = document.createElement("div");
        suggestionDiv.classList.add('result')
        suggestionDiv.innerHTML = `<strong>${name.substr(0, val.length)}</strong>${name.substr(val.length)}`;
        suggestionDiv.addEventListener("click", () => {
          this.inputTarget.value = name;
          this.closeAllLists();
        });
        this.resultItemsTarget.appendChild(suggestionDiv);
      }
    });

    let items = this.resultItemsTarget.querySelectorAll('.result')
    if (items.length === 0) { this.resultsTarget.classList.remove('open'); }
  }

  onKeyDown(event) {
    let items = this.resultItemsTarget.querySelectorAll('.result')
    if (event.key === "ArrowDown") {
      this.currentFocus++;
      this.addActive();
    } else if (event.key === "ArrowUp") {
      this.currentFocus--;
      this.addActive();
    } else if (event.key === "Enter") {
      event.preventDefault();
      if (this.currentFocus > -1 && items) items[this.currentFocus].click();
    }
  }

  addActive() {
    let items = this.resultItemsTarget.querySelectorAll('.result')
    if (items.length == 0) {
      this.resultsTarget.classList.remove('open');
      return false;
    }
    this.removeActive(items);
    if (this.currentFocus >= items.length) this.currentFocus = 0;
    if (this.currentFocus < 0) this.currentFocus = items.length - 1;
    items[this.currentFocus].classList.add("autocomplete-active");
  }

  removeActive(items) {
    Array.from(items).forEach(item => item.classList.remove("autocomplete-active"));
  }

  closeAllLists(target) {
    if (!this.hasResultsTarget) { return; }
    this.resultsTarget.classList.remove('open');
    const items = this.resultItemsTarget.querySelectorAll('.result');
    Array.from(items).forEach(item => {
      if (target !== item && target !== this.inputTarget) {
        item.parentNode.removeChild(item);
      }
    });
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutside);
  }
}