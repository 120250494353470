import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'input', 'element' ]

  connect() {
    this.toggle()
  }

  inputTargetConnected(target) {
    this.toggle()
    target.addEventListener('input', () => { this.toggle() })
  }

  inputTargetDisconnected(_target) {
    this.toggle()
  }

  toggle() {
    if (this.inputTargets.some((input) => input.value)) {
      this.elementTarget.classList.remove('hidden')
    } else {
      this.elementTarget.classList.add('hidden')
    }
  }
}
