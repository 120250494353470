import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ['item'];

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: '.handle',
      animation: 150,
      onEnd: this.updatePositions.bind(this),
    });
  }

  updatePositions(_event) {
    this.itemTargets.forEach((item, index) => {
      const positionInput = item.querySelector("[data-position]");
      if (positionInput) {
        positionInput.value = index + 1;
        positionInput.dispatchEvent(new Event('change'))
      }
    });
  }
}