export function initializeLSArray(key) {
  let array = JSON.parse(localStorage.getItem(key)) || [];
  localStorage.setItem(key, JSON.stringify(array));
}

export function addLSElement(key, newElement) {
  let array = JSON.parse(localStorage.getItem(key)) || [];
  if (!array.includes(newElement)) {
    array.push(newElement);
    localStorage.setItem(key, JSON.stringify(array));
  }
}


export function removeLSElementByName(key, elementName) {
  let array = JSON.parse(localStorage.getItem(key)) || [];
  const index = array.indexOf(elementName);
  if (index !== -1) {
    array.splice(index, 1);
    localStorage.setItem(key, JSON.stringify(array));
  }
}

export function getLSElements(key) {
  const storedData = localStorage.getItem(key);
  return storedData ? JSON.parse(storedData) : [];
}

export function clearLSArray(key) {
  localStorage.setItem(key, JSON.stringify([]));
}

export function deleteLSArray(key) {
  localStorage.removeItem(key);
}