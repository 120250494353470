import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'field', 'button' ]

  fieldTargetConnected() {
    this._setElementsVisibility()
  }

  show() {
    this.fieldTarget.classList.remove('hidden')
    this.buttonTarget.classList.add('hidden')
  }

  onChange() {
    this._setElementsVisibility()
  }

  _setElementsVisibility() {
    let inputElement = this.fieldTarget.querySelector('select')
    if (inputElement.value) {
      this.buttonTarget.classList.add('hidden')
      this.fieldTarget.classList.remove('hidden')
    } else {
      this.buttonTarget.classList.remove('hidden')
      this.fieldTarget.classList.add('hidden')
    }
  }
}