import { Controller } from '@hotwired/stimulus'
import { generateEid } from 'helpers'

export default class extends Controller {
  static targets = ['input']

  inputTargetConnected(target) {
    if (!target.value) {
      const eid = generateEid()
      target.value = eid
      target.closest('[data-eid]').dataset.eid = eid
    }
  }
}
