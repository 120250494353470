export function csrfToken() {
  const meta = document.querySelector('meta[name=csrf-token]');
  const token = meta && meta.getAttribute('content');

  return token ?? false;
}

export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

export function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

export function toArray(value) {
  if (Array.isArray(value)) {
    return value;
  } else if (Array.from) {
    return Array.from(value);
  } else {
    return [].slice.call(value);
  }
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

export function getControllerByIdentifier(controllerObject, identifier) {
  return controllerObject.application.controllers.find(controller => {
    return controller.context.identifier === identifier;
  });
}

export function getAbsoluteOffsetTop(el) {
  let offset = 0;
  let currentElement = el;

  while (currentElement !== null) {
    offset += currentElement.offsetTop;
    offset -= currentElement.scrollTop;
    currentElement = currentElement.offsetParent;
  }

  return offset;
}

export function getAbsoluteOffsetLeft(el) {
  let offset = 0;
  let currentElement = el;

  while (currentElement !== null) {
    offset += currentElement.offsetLeft;
    offset -= currentElement.scrollLeft;
    currentElement = currentElement.offsetParent;
  }

  return offset;
}

export function getClosestNumber(array, value) {
  return array.reduce((prev, curr) => Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev);
}


export function convertStringToHTML(htmlString) {
  const parser = new DOMParser();
  const html = parser.parseFromString(htmlString.trim(), 'text/html');

  return html.body;
}

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
  }
  return array;
}

export function titleize(input) {
  return input
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
}

export function dedup(items) {
  return [...new Set(items)]
}

export function generateEid() {
    const eidLength = 11
    const array = new Uint32Array(1)

    crypto.getRandomValues(array)

    return String(array[0]).padEnd(eidLength, 0)
}

export function randomId() {
  const bytes = new Uint8Array(8)
  crypto.getRandomValues(bytes)

  return bytes.reduce((acc, byte) => {
    acc.push(byte.toString(16))
    return acc
  }, []).join('')
}
